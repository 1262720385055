import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgencyConfigState } from './agencyConfig.reducer';

export const agencyConfigStateSelector =
  createFeatureSelector<Readonly<AgencyConfigState>>('agencyConfig');

export const agencyConfigSelector = createSelector(
  agencyConfigStateSelector,
  (state) => {
    return state.agencyConfig;
  }
);
export const agencyLoader = createSelector(
  agencyConfigStateSelector,
  (state) => {
    return state.status;
  }
);

export const agency = createSelector(agencyConfigStateSelector, (state) => {
  return state.agencyConfig?.agency;
});
